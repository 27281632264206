import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getItems } from 'api';
import { openSnackbar } from './snackbar';

const initialState = {
  items: {},
};

export const fetchItems = createAsyncThunk(
  'item/fetchItems',
  async ({ dscr, page }, { dispatch }) => {
    getItems({ dscr, page })
      .then((result) => dispatch(setItems(result)))
      .catch((error) => {
        dispatch(openSnackbar({ open: true, message: error.message }));
      });
  },
);

const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const { setItems } = itemSlice.actions;
export default itemSlice.reducer;
