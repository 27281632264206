import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createSanitaryRecordAPI,
  deleteSanitaryRecordAPI,
  getSanitaryRecordsAPI,
  updateSanitaryRecordAPI,
} from 'api';
import { openSnackbar } from './snackbar';
import { setLoading } from './ui';

const initialState = {
  sanitaryRecordsList: [],
  sanitaryRecord: {},
  complete: false,
};

export const getSanitaryRecords = createAsyncThunk(
  'sanitaryRecords/getSanitaryRecords',
  async ({ value, page, limit }, { dispatch }) => {
    getSanitaryRecordsAPI({ value, page, limit: 10 })
      .then((result) => {
        dispatch(setSanitaryRecordsList(result.data ?? []));
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            open: true,
            message: error.message,
            alert: {
              color: 'error',
            },
          }),
        );
      });
  },
);
export const createSanitaryRecord = createAsyncThunk(
  'sanitaryRecords/createSanitaryRecord',
  async ({ file, code, name }, { dispatch }) => {
    createSanitaryRecordAPI({ file, code, name })
      .then((result) => dispatch(setSanitaryRecord(result)))
      .catch((error) => {
        dispatch(
          openSnackbar({
            open: true,
            message: error.message,
            alert: {
              color: 'error',
            },
          }),
        );
      });
  },
);
export const updateSanitaryRecord = createAsyncThunk(
  'sanitaryRecords/updateSanitaryRecord',
  async ({ file, code, name, id }, { dispatch, rejectWithValue }) => {
    updateSanitaryRecordAPI({ file, code, name, id })
      .then((result) => {
        console.log(result.data);
        dispatch(setSanitaryRecord(result.data ?? []));
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            open: true,
            message: error.message,
            alert: {
              color: 'error',
            },
          }),
        );
      });
  },
);

export const deleteSanitaryRecord = createAsyncThunk(
  'sanitaryRecords/deleteSanitaryRecord',
  async (id, { dispatch, getState }) => {
    deleteSanitaryRecordAPI(id)
      .then((result) => {
        const { sanitaryRecordsList } = getState().sanitaryRecords;
        console.log('🚀 ~ .then ~ sanitaryRecordsList:', sanitaryRecordsList);
        const updatedSanitaryRecord = sanitaryRecordsList.filter((element) => element.id !== id);
        dispatch(setSanitaryRecord(updatedSanitaryRecord)); // Dispatch the updated lotsList
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            open: true,
            message: error.message,
            alert: {
              color: 'error',
            },
          }),
        );
      });
  },
);

const sanitaryRecordsSlice = createSlice({
  name: 'sanitaryRecords',
  initialState,
  reducers: {
    setSanitaryRecord: (state, action) => {
      state.sanitaryRecord = action.payload;
    },
    setSanitaryRecordsList: (state, action) => {
      state.sanitaryRecordsList = action.payload;
    },
  },
});

export const { setSanitaryRecord, setSanitaryRecordsList } = sanitaryRecordsSlice.actions;
export default sanitaryRecordsSlice.reducer;
