/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { BASE_URL } from '../config';

const axiosServices = axios.create({
  baseURL: BASE_URL,
});

axiosServices.interceptors.request.use(
  function (config) {
    document.body.classList.add('loading-indicator');
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

// interceptor for http
axiosServices.interceptors.response.use(
  (response) => {
    document.body.classList.remove('loading-indicator');
    return response;
  },
  (error) => {
    document.body.classList.remove('loading-indicator');
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  },
);

export default axiosServices;
