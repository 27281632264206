import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getInvoicesAPI } from 'api';
import { openSnackbar } from './snackbar';
const initialState = {
  invoiceList: [],
  complete: false,
};

export const getInvoices = createAsyncThunk(
  'invoices/getInvoices',
  async ({ dateFrom, dateTo, type }, { dispatch }) => {
    getInvoicesAPI({ dateFrom, dateTo, type })
      .then((result) => {
        dispatch(setInvoiceList(result.data ?? []));
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            open: true,
            message: error.message,
            alert: {
              color: 'error',
            },
          }),
        );
      });
  },
);

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoiceList: (state, action) => {
      state.invoiceList = action.payload;
    },
  },
});
export const { setInvoiceList } = invoicesSlice.actions;
export default invoicesSlice.reducer;
