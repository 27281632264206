import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getVentc } from 'api';
import { openSnackbar } from './snackbar';

const initialState = {
  data: [],
};

export const fetchVentc = createAsyncThunk(
  'ventc/fetchVentc',
  async (searchParams, { dispatch }) => {
    // dispatch(setVentc([]));
    getVentc(searchParams)
      .then((result) => dispatch(setVentc(result)))
      .catch((error) => {
        dispatch(openSnackbar({ open: true, message: error.message }));
      });
  },
);

const ventcSlice = createSlice({
  name: 'ventc',
  initialState,
  reducers: {
    setVentc: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setVentc } = ventcSlice.actions;
export default ventcSlice.reducer;
