// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconNews,
  IconHandSanitizer,
  IconFileInvoice,
  IconArchive,
} from '@tabler/icons';

// constant
const icons = {
  IconBrandChrome,
  IconHelp,
  IconSitemap,
  IconNews,
  IconHandSanitizer,
  IconFileInvoice,
  IconArchive,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const verdezoto = {
  id: 'verdezoto-menu',

  icon: icons.IconHelp,
  type: 'group',
  children: [
    {
      id: 'repayments',
      title: <FormattedMessage id="repayments" />,
      type: 'item',
      icon: icons.IconArchive,
      url: '/repayments',
      children: [
        {
          id: 'repayments-new',
          title: <FormattedMessage id="repayments" />,
          type: 'item',
          icon: icons.IconNews,
          url: '/repayments/new',
          breadcrumbs: true,
        },
      ],
    },
    {
      id: 'lots',
      title: <FormattedMessage id="lots" />,
      type: 'item',
      icon: icons.IconNews,
      url: '/lots',
      children: [
        {
          id: 'lots',
          title: <FormattedMessage id="lots" />,
          type: 'item',
          icon: icons.IconNews,
          url: '/lots',
          breadcrumbs: true,
        },
      ],
    },
    {
      id: 'sanitary-records',
      title: <FormattedMessage id="sanitary-records" />,
      type: 'item',
      icon: icons.IconHandSanitizer,
      url: '/sanitary-records',
      children: [
        {
          id: 'sanitary-records',
          title: <FormattedMessage id="sanitary-records" />,
          type: 'item',
          icon: icons.IconHandSanitizer,
          url: '/sanitary-records',
          breadcrumbs: true,
        },
      ],
    },
    {
      id: 'invoices',
      title: <FormattedMessage id="invoices" />,
      type: 'item',
      icon: icons.IconFileInvoice,
      url: '/invoices',
      children: [
        {
          id: 'invoices',
          title: <FormattedMessage id="invoices" />,
          type: 'item',
          icon: icons.IconFileInvoice,
          url: '/invoices',
          breadcrumbs: true,
        },
      ],
    },
  ],
};

export default verdezoto;
