import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getClient } from 'api';
import { openSnackbar } from './snackbar';

const initialState = {
  data: {},
};

export const fetchClient = createAsyncThunk('client/fetchClient', async (_, { dispatch }) => {
  getClient()
    .then((result) => dispatch(setClient(result.data)))
    .catch((error) => {
      dispatch(openSnackbar({ open: true, message: error.message }));
    });
});

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClient: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setClient } = clientSlice.actions;
export default clientSlice.reducer;
