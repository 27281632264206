import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  console.log('🚀 ~ file: JWTContext.js:30 ~ verifyToken ~ decoded:', decoded);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        // if (serviceToken && verifyToken(serviceToken)) {
        if (serviceToken) {
          setSession(serviceToken);
          const response = await axios.get('/ms-verdezoto-auth/v1/auth/me');

          const user = response.data.data;

          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post('/ms-verdezoto-auth/v1/auth', { username: email, password });
    const { token: serviceToken, user } = response.data;
    setSession(serviceToken);
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
    });
  };

  const register = async ({
    email,
    password,
    verifyPassword,
    firstName,
    lastName,
    phone,
    ciruc,
  }) => {
    const response = await axios.post('/ms-verdezoto-auth/v1/users', {
      email,
      password,
      verifyPassword,
      firstName,
      lastName,
      phone,
      ciruc,
    });
    let users = response.data.data;

    // if (
    //   window.localStorage.getItem('users') !== undefined &&
    //   window.localStorage.getItem('users') !== null
    // ) {
    //   const localUsers = window.localStorage.getItem('users');
    //   console.log('🚀 ~ file: JWTContext.js:127 ~ useEffect ~ localUsers:', localUsers);
    //   users = [
    //     ...JSON.parse(localUsers),
    //     {
    //       email,
    //       password,
    //       name: `${firstName} ${lastName}`,
    //     },
    //   ];
    // }
    // window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = (email) => console.log(email);

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{ ...state, login, logout, register, resetPassword, updateProfile }}>
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
