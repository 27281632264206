import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getReasonReturn } from 'api';
import { openSnackbar } from './snackbar';

const initialState = {
  reasonReturn: [],
};

export const fetchReasonReturn = createAsyncThunk(
  'general/fetchReasonReturn',
  async (_, { dispatch }) => {
    getReasonReturn()
      .then((result) => dispatch(setReasonReturn(result.data)))
      .catch((error) => {
        dispatch(openSnackbar({ open: true, message: error.message }));
      });
  },
);

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setReasonReturn: (state, action) => {
      state.reasonReturn = action.payload;
    },
  },
});

export const { setReasonReturn } = generalSlice.actions;
export default generalSlice.reducer;
