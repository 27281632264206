import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
// import Logo from 'ui-component/Logo';
import LogoDjv from '../../../assets/images/logo-djv.svg';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <Link component={RouterLink} to={DASHBOARD_PATH}>
    <img src={LogoDjv} alt="DJV" width="35" />
  </Link>
);

export default LogoSection;
