import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

const AppUserAccountProfile = Loadable(
  lazy(() => import('views/application/users/account-profile/profile')),
);

const InitPage = Loadable(lazy(() => import('views/init-page')));
const Repayments = Loadable(lazy(() => import('views/application/repayments/create')));
const RepaymentList = Loadable(lazy(() => import('views/application/repayments/list')));
const Lots = Loadable(lazy(() => import('views/application/lots/create')));
const InvoicesList = Loadable(lazy(() => import('views/application/invoices/list/InvoiceList')));
const SanitaryRecords = Loadable(lazy(() => import('views/application/sanitaryRecords/create')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      element: <InitPage />,
    },
    {
      path: '/init-page',
      element: <InitPage />,
    },
    {
      path: '/user/account-profile/profile',
      element: <AppUserAccountProfile />,
    },
    {
      path: '/repayments',
      element: <RepaymentList />,
    },
    {
      path: '/repayments/new/:id',
      element: <Repayments />,
    },
    {
      path: '/lots',
      element: <Lots />,
    },
    {
      path: '/invoices',
      element: <InvoicesList />,
    },
    {
      path: '/sanitary-records',
      element: <SanitaryRecords />,
    },
  ],
};

export default MainRoutes;
