import axios from 'utils/axios';
import { LIMIT_PAGINATION } from '../config';

export const getReasonReturn = async () => {
  return axios
    .get('/ms-verdezoto-devoluciones/v1/general/reasons-return')
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const getClient = async () => {
  return axios
    .get('/ms-verdezoto-devoluciones/v1/clients')
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const getItems = async ({ dscr = '', limit = LIMIT_PAGINATION, page = 0 }) => {
  return axios
    .get(`/ms-verdezoto-devoluciones/v1/items?dscr=${dscr}&limit=${limit}&page=${page}`)
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const getVentc = async ({ item, from_date, to_date, cdal }) => {
  let searchParams = `from_date=${from_date}&to_date=${to_date}`;
  if (item) {
    searchParams = `${searchParams}&item=${item}`;
  }
  if (cdal) {
    searchParams = `${searchParams}&cdal=${cdal}`;
  }
  return axios
    .get(`/ms-verdezoto-devoluciones/v1/ventc?${searchParams}`)
    .then((res) => res.data.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const createRepaymentAPI = async (details = []) => {
  return axios
    .post(`/ms-verdezoto-devoluciones/v1/repayments`, { details })
    .then((res) => res.data.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const createRepaymentDetailAPI = async (repaymentHeaderId, details = []) => {
  return axios
    .post(`/ms-verdezoto-devoluciones/v1/repayments/detail/${repaymentHeaderId}`, { details })
    .then((res) => res.data.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const deleteRepaymentDetailAPI = async (repaymentDetailId) => {
  return axios
    .delete(`/ms-verdezoto-devoluciones/v1/repayments/detail/${repaymentDetailId}`)
    .then((res) => res.data.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const getRepaymentsByUserIdAPI = async () => {
  return axios
    .get(`/ms-verdezoto-devoluciones/v1/repayments`)
    .then((res) => {
      return res.data.data;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const getRepaymentByCodeAPI = async (code) => {
  return axios
    .get(`/ms-verdezoto-devoluciones/v1/repayments/code/${code}`)
    .then((res) => res.data.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const completeRepaymentAPI = async (repaymentId, reasonId) => {
  return axios
    .post(`ms-verdezoto-devoluciones/v1/repayments/complete/${repaymentId}`, { reasonId })
    .then((res) => res.data.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const updatePersonAPI = async (person) => {
  return axios
    .put(`ms-verdezoto-auth/v1/people`, person)
    .then((res) => res.data.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};
//************LOTS********** */
export const getLotsAPI = async ({ value = '', page, limit }) => {
  return axios
    .get(`/ms-verdezoto-lotes/v1/lots?limit=${limit}&page=${page}&value=${value}`)
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const updateLotsAPI = async ({ file, lot, name, id }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('lot', lot);
  formData.append('name', name);
  return axios
    .put(`/ms-verdezoto-lotes/v1/lots/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};
export const createLotsAPI = async ({ file, lot, name }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('lot', lot);
  formData.append('name', name);
  return axios
    .post(`/ms-verdezoto-lotes/v1/lots`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const deleteLotAPI = async ({ id }) => {
  return axios
    .delete(`/ms-verdezoto-lotes/v1/lots/${id}`)
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

//************INVOICES********** */
export const getInvoicesAPI = async ({ dateFrom, dateTo, type }) => {
  return axios
    .get(
      `/ms-verdezoto-facturas/v1/invoice?fechaInicio=${dateFrom}&fechaFin=${dateTo}&tipoComprobante=${type}`,
    )
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};
//************SANITARY RECORDS********** */
export const getSanitaryRecordsAPI = async ({ value = '', page, limit }) => {
  return axios
    .get(`/ms-verdezoto-lotes/v1/sanitary-records?limit=${limit}&page=${page}&value=${value}`)
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};

export const updateSanitaryRecordAPI = async ({ file, code, name, id }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('code', code);
  formData.append('name', name);
  return axios
    .put(`/ms-verdezoto-lotes/v1/sanitary-records/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};
export const createSanitaryRecordAPI = async ({ file, code, name }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('code', code);
  formData.append('name', name);
  return axios
    .post(`/ms-verdezoto-lotes/v1/sanitary-records`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)
    .catch(function (error) {
      console.log('🚀 ~ createSanitaryRecordAPI ~ error:', JSON.stringify(error));
      return Promise.reject(error);
    });
};

export const deleteSanitaryRecordAPI = async ({ id }) => {
  return axios
    .delete(`/ms-verdezoto-lotes/v1/sanitary-records/${id}`)
    .then((res) => res.data)
    .catch(function (error) {
      return Promise.reject(error);
    });
};
