import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updatePersonAPI } from 'api';
import { openSnackbar } from './snackbar';
import { setLoading } from './ui';
import axios from 'utils/axios';

const initialState = {
  data: {},
};

export const updatePerson = createAsyncThunk('person/updatePerson', async (data, { dispatch }) => {
  dispatch(setLoading(true));
  updatePersonAPI(data.person)
    .then((result) => {
      dispatch(
        openSnackbar({
          open: true,
          message: `Cuenta actualizada correctamente, ${
            data.updatedCiruc ? 'por favor vuelva a iniciar sesión' : ''
          }`,
          alert: {
            color: 'success',
          },
        }),
      );
      if (data.updatedCiruc === true) {
        // eleiminar el token para que se vuelva a loguear
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
        setTimeout(() => {
          window.location.href = '/login';
        }, 2500);
      } else {
        dispatch(setDataPerson(result));
      }
    })

    .catch((error) => {
      dispatch(openSnackbar({ open: true, message: error.message }));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
});

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    setDataPerson: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setDataPerson } = personSlice.actions;
export default personSlice.reducer;
