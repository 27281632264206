import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createLotsAPI, deleteLotAPI, getLotsAPI, updateLotsAPI } from 'api';
import { openSnackbar } from './snackbar';
import { setLoading } from './ui';

const initialState = {
  lotsList: [],
  lots: {},
  complete: false,
};

export const getLots = createAsyncThunk(
  'lots/getLots',
  async ({ value, page, limit }, { dispatch }) => {
    getLotsAPI({ value, page, limit: 10 })
      .then((result) => {
        // console.log(result.data);
        dispatch(setLotsList(result.data ?? []));
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            open: true,
            message: error.message,
            alert: {
              color: 'error',
            },
          }),
        );
      });
  },
);
export const createLots = createAsyncThunk(
  'lots/createLots',
  async ({ file, lot, name }, { dispatch }) => {
    createLotsAPI({ file, lot, name })
      .then((result) => dispatch(setLots(result)))
      .catch((error) => {
        dispatch(
          openSnackbar({
            open: true,
            message: error.message,
            alert: {
              color: 'error',
            },
          }),
        );
      });
  },
);
export const updateLots = createAsyncThunk(
  'lots/updateLots',
  async ({ file, lot, name, id }, { dispatch, rejectWithValue }) => {
    updateLotsAPI({ file, lot, name, id })
      .then((result) => {
        console.log(result.data);
        dispatch(setLots(result.data ?? []));
      })
      .catch((error) => {
        dispatch(
          openSnackbar({
            open: true,
            message: error.message,
            alert: {
              color: 'error',
            },
          }),
        );
      });
  },
);

export const deleteLot = createAsyncThunk('lots/deleteLot', async (id, { dispatch, getState }) => {
  deleteLotAPI(id)
    .then((result) => {
      console.log(result.data);
      const { lotsList } = getState().lots; // Access lotsList from the state// Log or use lotsList as needed
      const updatedLots = lotsList.filter((element) => element.id !== id);
      dispatch(setLots(updatedLots)); // Dispatch the updated lotsList
    })
    .catch((error) => {
      dispatch(
        openSnackbar({
          open: true,
          message: error.message,
          alert: {
            color: 'error',
          },
        }),
      );
    });
});

const lotsSlice = createSlice({
  name: 'lots',
  initialState,
  reducers: {
    setLots: (state, action) => {
      state.lots = action.payload;
    },
    setLotsList: (state, action) => {
      state.lotsList = action.payload;
    },
  },
});

export const { setLots, setLotsList, dele } = lotsSlice.actions;
export default lotsSlice.reducer;
