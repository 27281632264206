import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createRepaymentAPI,
  createRepaymentDetailAPI,
  deleteRepaymentDetailAPI,
  getRepaymentsByUserIdAPI,
  getRepaymentByCodeAPI,
  completeRepaymentAPI,
} from 'api';
import { openSnackbar } from './snackbar';

import { setLoading } from './ui';

const initialState = {
  data: {},
  data_list: [],
  complete: false,
};

export const getRepayments = createAsyncThunk(
  'repayment/getRepayments',
  async (_, { dispatch }) => {
    getRepaymentsByUserIdAPI()
      .then((result) => {
        dispatch(setDataListRepayment(result));
      })
      .catch((error) => {
        dispatch(openSnackbar({ open: true, message: error.message }));
      });
  },
);

export const getRepaymentByCode = createAsyncThunk(
  'repayment/getRepaymentByCode',
  async ({ code }, { dispatch }) => {
    getRepaymentByCodeAPI(code)
      .then((result) => dispatch(setDataRepayment(result)))
      .catch((error) => {
        dispatch(openSnackbar({ open: true, message: error.message }));
      });
  },
);

export const createRepayment = createAsyncThunk(
  'repayment/createRepayment',
  async (_, { dispatch }) => {
    createRepaymentAPI()
      .then((result) => dispatch(setDataRepayment(result)))
      .catch((error) => {
        dispatch(openSnackbar({ open: true, message: error.message }));
      });
  },
);

export const createRepaymentDetail = createAsyncThunk(
  'repayment/createRepaymentDetail',
  async ({ repaymentHeaderId, details }, { dispatch }) => {
    dispatch(setLoading(true));
    createRepaymentDetailAPI(repaymentHeaderId, details)
      .then((result) => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Producto agregado',
            alert: {
              color: 'success',
            },
          }),
        );
        dispatch(setDataRepayment(result));
      })
      .catch((error) => {
        dispatch(openSnackbar({ open: true, message: error.message, autoHideDuration: 20000 }));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  },
);

export const deleteRepaymentDetail = createAsyncThunk(
  'repayment/deleteRepaymentDetail',
  async ({ repaymentDetailId }, { dispatch }) => {
    dispatch(setLoading(true));
    deleteRepaymentDetailAPI(repaymentDetailId)
      .then((result) => {
        dispatch(setDataRepayment(result));
      })
      .catch((error) => {
        dispatch(openSnackbar({ open: true, message: error.message }));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  },
);

export const completeRepayment = createAsyncThunk(
  'repayment/completeRepayment',
  async ({ repaymentId, reasonId }, { dispatch }) => {
    completeRepaymentAPI(repaymentId, reasonId)
      .then((result) => dispatch(setCompleteRepayment(true)))
      .catch((error) => {
        dispatch(openSnackbar({ open: true, message: error.message }));
      });
  },
);

const repaymentSlice = createSlice({
  name: 'repayment',
  initialState,
  reducers: {
    setDataRepayment: (state, action) => {
      state.data = action.payload;
    },
    setDataListRepayment: (state, action) => {
      state.data_list = action.payload;
    },
    setCompleteRepayment: (state, action) => {
      state.complete = action.payload;
    },
  },
});

export const { setDataRepayment, setDataListRepayment, setCompleteRepayment } =
  repaymentSlice.actions;
export default repaymentSlice.reducer;
