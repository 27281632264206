// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import uiReducer from './slices/ui';
import generalReducer from './slices/general';
import clientReducer from './slices/client';
import itemReducer from './slices/item';
import ventcReducer from './slices/ventc';
import repaymentReducer from './slices/repayment';
import personReducer from './slices/person';
import lotsReducer from './slices/lots';
import invoicesReducer from './slices/invoices';
import sanitaryRecordsReducer from './slices/sanitaryRecords';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  menu: menuReducer,
  ui: uiReducer,
  general: generalReducer,
  client: clientReducer,
  item: itemReducer,
  ventc: ventcReducer,
  repayment: repaymentReducer,
  person: personReducer,
  lots: lotsReducer,
  invoices: invoicesReducer,
  sanitaryRecords: sanitaryRecordsReducer,
});

export default reducer;
