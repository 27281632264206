import verdezoto from './verdezoto';
import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [verdezoto],
};

export default menuItems;
